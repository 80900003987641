<template>
  <div class="card mb-2 card-note" :class="classComputed">
    <div class="card-header" v-if="hasHeaderSlot">
      <slot name="header" />
    </div>
    <b-col class="p-0">
      <div class="timestamp">
        {{ timestamp }}
      </div>
      <div class="anotes">
        <p>{{ note.libelle }}</p>
        <!-- <p class="type">{{ note.type }}</p>
        <p class="coeff">{{ note.coeff }}%</p> -->
        <p class="mark1" :class="aboveAverage ? 'successfull' : 'failed'">
          {{ note.note }}/20
        </p>
      </div>
    </b-col>
  </div>
</template>

<script>
export default {
  name: "Card",
  props: {
    variant: {
      type: String,
      default: "primary",
    },
    boxShadow: Boolean,
    note: {
      type: Object,
      required: true,
    },
    isRecent: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classComputed: function () {
      // glass, primary, colorfull, transparent
      return `${this.variant} ${this.boxShadow ? "card-shadow" : ""}`;
    },
    hasHeaderSlot() {
      return !!this.$slots["header"];
    },
    hasFooterSlot() {
      return !!this.$slots["footer"];
    },
    aboveAverage() {
      return this.note.note >= 10;
    },
    timestamp() {
      return new Date(this.note.date_obtention).toLocaleDateString(this.$i18n.locale);
    }
  },
};
</script>

<style scoped lang="scss">
.anotes {
  display: flex;
  justify-content: space-between;

  .type {
    width: 60%;
  }
  .mark1,
  .coeff {
    width: 20%;
    text-align: right;
  }
}
.card {
  margin-bottom: 0.25em;

  .timestamp {
    text-transform: capitalize;
  }
}
</style>